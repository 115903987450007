<template>
	<component
		:is="(isLink) ? 'router-link' : 'button'"
		class="Button__Reactive App__Button lg"
		:class="btnType"
		:to="to"
		@click="onClick">

		<slot name="loading" v-if="btnState == 'loading'">
			<i class="fas fa-circle-notch fa-spin" style="margin-right: 3px; font-size: 13px"></i>
			loading..
		</slot>
		<slot name="failure" v-else-if="btnState == 'failure'">
			failed
		</slot>
		<slot name="success" v-else-if="btnState == 'success'">success</slot>
		<slot name="initial" v-else>submit</slot>

	</component>
</template>

<script>
export default
{
  	name: "ReactiveButton",
	props:
	[
		'btnType',
		'btnState',
		'isLink',
		'to'
	],
	data()
	{
		return {
			_btnState: ''
		}
	},
	emits: ['click'],

	methods:
	{
		onClick: function(event)
		{
			if (!this.isLink)
			{
				event.preventDefault()
				this.$emit('click', this)
			}
		},
	}
}
</script>

<style scoped lang="scss">
	.Button__Reactive
	{
		&>*
		{
			display: inline-block;
		}

		&.basic { background: $color_background_button_basic; }
		&.danger { background: $color_background_button_danger; }
		&.warning { background: $color_background_button_warning; }
		&.success { background: $color_background_button_success; }

		&.login-custom
		{
			background: #2b2b2b;
			color: #ffffffe6;
			font-weight: normal;
			border: 1px solid black;
			border-radius: 7px;
		}
	}
</style>
