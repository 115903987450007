// define a mixin object
import axios from "axios";
import store from '../store'

export const HTTPRequest =
{
	methods:
	{
		request: function (type, url, options, data)
		{
			return new Promise(async function (resolve)
			{
				try
				{
					for (const key in store.state.headers)
					{
						axios.defaults.headers.common[key] = store.state.headers[key]
					}

					let result = await axios.request({ method: type, url: url, baseURL: process.env.VUE_APP_API_URL, data: data, ...options });

					if (result.data.status != 200)
					{
						resolve({ data: null, error: result.data, status: result.data.status })
						return;
					}

					resolve({ data: result.data.content, error: null, status: result.data.status })
				}
				catch (error)
				{
					console.log("HTTPRequest: Caught Error, resolving with error")
					resolve({ data: null, error: JSON.stringify(error) })
				}
			})
		},

		

        api: async function(type, resource, options = {}, data = {})
        {
			let results = this.request.apply(this, [type, process.env.VUE_APP_API_URL + resource, options, data])
			return results
        }
	}
}