<template>
	<div class="Page__InputContainer">

		<!-- Basic text input, with optional inline submission -->
		<template v-if="!inputType || inputType == 'text'">

			<label class="Page__InputLabel"><slot name="label" v-if="this.$slots.label"></slot></label>
			<div class="Page__Input MarginTop_x0">
				<div class="App__InputLeftIcon" v-if="this.$slots.leftIcon">
					 <slot name="leftIcon"></slot>
				</div>
				<input
					:type="(fieldType) ? fieldType : 'text'"
					:placeholder="placeholderText"
					:value="value"
					:class="{ 'PageInput__Capitalise': capitalise, 'PageInput__Lowercase': lowercase }"
					@keyup.enter="enterHandler"
					@input="$emit('update:value', $event.target.value)"
				/>
			</div>
		</template>
	</div>
</template>

<script>

export default
{
	name: "FormInput",
	props:
		{
			value: String | Number,
			inputType: String,
			placeholderText: String,
			fieldType: String,
			inputTitle: String,
			modelValue: String,
			capitalise: Boolean,
			lowercase: Boolean,
		},
	methods:
	{

		enterHandler: function(data)
		{
			if (this.status == 1)
			{
				this.$emit('enterEvent', this.inputVal)
			}
		}
	},

	computed:
	{
		inputVal:
		{
			get()
			{
				return this.modelValue;
			},
			set(val)
			{
				this.$emit('update:modelValue', val);
			}
		}
	}

}
</script>

<style scoped lang="scss">

.Page__InputLabel
{
	font-weight: bold;
	font-size: 11px;
	margin-left: 2px;
	margin-bottom: 1px;
}

.Page__Input
{
	border: 1px solid #0009;
	padding: 6px 8px;
	display: block;
	width: 100%;
	border-radius: var(--border_radius_main);
	-webkit-transition: all 0.3s;
	transition: all 0.2s;
	background: #fff;

	&>input
	{
		font-size: 12px;
		width: 100%;
	}

	&:focus, &:hover
	{
		box-shadow: $box_shadow_button;
	}

}


.Page__InputContainer
{
	position: relative;
}

.Page__Input_StateContainer
{
	position: absolute;
	right: 18px;
	top: 52%;
	color: #707173;
}

.PageInput__submitText
{
	color: #676767;
}

.PageInput__Loading
{
	background: #eee;
	cursor: no-drop;
	pointer-events: none;
}

.PageInput__submitText.success
{
	color: green;
}

.PageInput__Success
{
	background: #74a2741c;
}

.PageInput__Capitalise { text-transform: capitalize; }
.PageInput__Lowercase { text-transform: lowercase; }

</style>
