<template>
	<div class="Page__InputContainer" v-on:mouseover="mouse_over_handler">

		<!-- Basic text input, with optional inline submission -->
		<template v-if="inputType == 'button'">
			<label class="Page__InputLabel"><slot name="label"></slot></label>
			<div class="Page__InputInputContainer">
				<span class="Page__Input button" 					
					  v-bind:class="{ 
						'PageInput__Loading': status == 2, 
						'PageInput__Success': status == 3, 
						'PageInput__Capitalise': capitalise, 
						'PageInput__Lowercase': lowercase, 
						'PageInput__PosLeft': position == 'left', 
						'PageInput__PosMid': position == 'middle',
						'PageInput__PosRight': position == 'right' 
					  }"
					  >
					  <slot name="content"></slot>
				</span>				

			</div>
		</template>

		<template v-if="!inputType || inputType == 'text' || inputType == 'number' || inputType == 'otp' || inputType == 'tel'">

			<label class="Page__InputLabel" v-if="has_label"><slot name="label"></slot></label>
			<div class="Page__InputInputContainer">
				<div class="Page__InputFollowerText" 
                     v-bind:style="{ 'left': input_width + 'px' }" v-if="input_width > 0 && internal_input_value && hasFollowerText">
					<slot name="followerText"></slot>
				</div>

				<input 
					class="Page__Input" 
					
					:name="name"
               		:autocomplete="autocomplete"
					:type="inputType || 'text'" 
					:placeholder="placeholderText" 
					:value="internal_input_value" 
					:maxlength="maxlength"
					:min="min" :max="max"
					:disabled="disabled"

                    v-validate="validate"
					v-bind:class="{ 
						'PageInput__Loading': status == 2, 
						'PageInput__Success': status == 3, 
						'PageInput__Capitalise': capitalise, 
						'PageInput__Uppercase': uppercase,
						'PageInput__Lowercase': lowercase, 
						'PageInput__PosLeft': position == 'left', 
						'PageInput__PosMid': position == 'middle',
						'PageInput__PosRight': position == 'right' 
					}"
					@keyup.enter="enterHandler" ref="inputBox"
					@input="on_input_value_change"
					@paste="on_input_value_change"
				/>
			</div>


			<div class="Page__Input_StateContainer">
				<template v-if="status == 2">
					<i class="fas fa-spinner fa-spin"></i>
					Validating
				</template>

				<div class="PageInput__submitText" v-if="status == 1" @click="enterHandler">
					Check
					<i class="fas fa-arrow-right"></i>
				</div>

				<div class="PageInput__submitText success" v-if="status == 3">
					<span v-if="customValidationText">{{ customValidationText }}</span>
					<span v-if="!customValidationText">Done</span>
					<i class="fas fa-check"></i>
				</div>

				<div class="PageInput__submitText" v-if="status == 4"><i class="far fa-times-circle"></i> Error</div>
			</div>
		</template>

		<template v-if="inputType === 'radio'">
			<label class="Page__InputInputContainer Page__InputRadioContainer customCheckbox" :for="id + value" v-bind:class="{ 'active': internal_input_value == value }">
				<input type="radio" :name="id" :id="id + value" :value="value" @change="on_input_value_change($event, 'radio')">
				<span class="checkmark"></span>

				<template v-if="$slots.label && id"><slot name="label"></slot></template>
				<template v-if="$slots.label && !id">err: field has label but no ID</template>
			</label>

		</template>


		<template v-if="inputType === 'dropdown'">

			<label class="Page__InputLabel Page__Flex M_Row" v-if="$slots.label">
				<slot name="label"></slot>
			</label>
			<div class="Page__InputInputContainer">
				<select :name="name" class="Page__Input" @change="on_input_value_change($event, 'dropdown')"
					v-bind:class="{ 'PageInput__smd': (smd || (padding_size && padding_size == 'smd')), }"
				>
					<option value="" selected disabled hidden>
						<template v-if="placeholderText">default: {{ placeholderText }}</template>
						<template v-else>Choose One</template>
					</option>
					<option v-for="(value, $index) in values_internal" :key="value._id || $index" :value="value.value || value">
						{{ value.title || value }}{{ (afterSelectionLabel) ? ' ' + afterSelectionLabel : ''  }}
					</option>
				</select>
			</div>
		</template>


		<template v-if="inputType === 'searchableDropdown'">

			<label class="Page__InputLabel Page__Flex M_Row">
				<slot name="label"></slot>
                <p class="Page__Text small MarginLeft_x1" v-if="multiple">(can select multiple)</p>
			</label>

			<div class="Page__InputDropdownContainer" 
				 v-clicked-outside="onUnFocusDropdown" 
				 v-bind:class="{ 'open': isInputFocused, 'hasSelected': ((multiple) 
									? !isInputFocused && (dropdownItemSelected && dropdownItemSelected.length > 0)
									: dropdownItemSelected),
								 'loading_active': http_loading
							   }">

				<div class="Page__Input dropdown" 
					 @click="onFocusDropdown" 
					 v-bind:class="{ 'PageInput__Disabled': disabled, 
									 'PageInput__Loading': status == 2, 
					 				 'PageInput__Success': status == 3, 
									 'PageInput__Capitalise': capitalise, 
									 'PageInput__Lowercase': lowercase, 
									 'PageInput__smd': smd, 
									 'PageInput__DropdownOpen': isInputFocused }">

					<div class="Page__DropdownSelectedItems" v-if="http_error">
						<div class="Page__Badge">
							<i class="far fa-times-circle"></i>
							Server Error
						</div>
					</div>

					<input class="" 
						   ref="dropdownSearchInput"
						   v-else-if="(multiple) 
									? isInputFocused || (!dropdownItemSelected || dropdownItemSelected.length < 1)
									: !dropdownItemSelected" 
						   type="text" :placeholder="placeholderText" 
						   :value="internal_input_value" 
						   :maxlength="maxlength"
						   @focus="onFocusDropdown" 
						   @input="on_input_value_change($event, 'searchableDropdown')" 
						   @keyup.enter="enterHandler" 
						   @keyup.esc="onUnFocusDropdown" />

					<!-- While an item is selected, but no data is yet available, show a loader -->
					<div class="Page__DropdownSelectedItems" v-else-if="http_loading">
						<div class="Page__Badge">
							<i class="fa-duotone fa-spinner-third fa-spin"></i>
							Fetching Data
						</div>
					</div>

					<div class="Page__DropdownSelectedItems Page__Flex M_Row" v-else>
						<!-- <strong id="kekw">Selected: </strong>  -->
						<div v-if="multiple">
							<div class="Page__Badge MarginRight_x1" v-if="selected"
								 v-for="(selected, $index) in dropdownItemSelected.slice(0,2)" 
								 :key="(selected) ? selected._id : $index">
								{{ selected.name }} <i class="fas fa-times"></i>
							</div>
							<div class="Page__Badge MarginRight_x1" v-if="dropdownItemSelected.length > 2">
								+ {{ dropdownItemSelected.length - 2}} more
							</div>
						</div>
						<div v-else-if="dropdownItemSelected">
							<div class="Page__Badge">{{ dropdownItemSelected.name }} <i class="fas fa-times"></i></div>
						</div>
					</div>
				</div>

				<div class="PageInput__DropdownItemContainer" v-if="isInputFocused && filteredDropdownItems">
					<!-- When dropdown is opened, these will show inside -->
					<div class="Page__DropdownSelectedItemsInner Page__Flex wrap M_Row" v-if="(multiple) ? dropdownItemSelected && dropdownItemSelected.length > 0 : dropdownItemSelected">
						<strong>Selected: </strong>
						<template v-if="multiple">
							<div class="Page__Badge" v-for="selected in dropdownItemSelected" :key="selected._id" @click="onDropdownSelectedItem(selected, true)">
								{{ selected.name }} <i class="fas fa-times"></i>
							</div>
						</template>
						<template v-else>
							<div class="Page__Badge">{{ dropdownItemSelected.name || dropdownItemSelected }} <i class="fas fa-times"></i></div>
						</template>
					</div>

					<div class="Page__Text small PageInput__DropdownItem" 
						v-bind:class="{ 'no-drop': !internal_input_value }"
						v-if="searchable_dropdown_http_error || !internal_input_value || (internal_input_value 
						 	   && internal_input_value.length > 0 
							   && filteredDropdownItems.length < 1)">
								<template v-if="searchable_dropdown_http_error">
									<strong><i class="fas fa-info-circle"></i> Error:</strong> 
									{{ searchable_dropdown_http_error }}
							   </template>
							   <template v-if="internal_input_value 
						 	   && internal_input_value.length > 0 
							   && filteredDropdownItems.length < 1">
									<strong><i class="fas fa-info-circle"></i> Info:</strong> 
									It seems none of the available choices match your search criteria,
									please try again using a different phrase.
							   </template>
							   <template v-if="!internal_input_value">
									<strong><i class="fas fa-info-circle"></i> Info:</strong> 
									Start typing to search.
							   </template>
					</div>

					<div class="PageInput__DropdownItem Page__Flex M_Row" 
						 v-for="(dropdownItem, $index) in filteredDropdownItems" 
						 @click="onDropdownSelectedItem(dropdownItem)"
						 :key="dropdownItem._id || $index">
						<div class="PageInput__DropdownItemIcon Page__FlexNoShrink" 
							 v-bind:style="{ 'background-image': 'url(' + dropdownItem.icon_url + ')' }"
							 v-bind:class="{ 'selected': (multiple && dropdownItemSelected && dropdownItemSelected.some(el => el['_id'] === dropdownItem._id )) }"></div>
						<p class="PageInput__DropdownItemHeading Page__Text Page__Flex M_Column Column">
							{{ dropdownItem.name }}
							{{ (multiple && dropdownItemSelected && dropdownItemSelected.some(el => el['_id'] === dropdownItem._id )) ? "(click to deselect)" : "" }}
							<span class="Page__Text xsmall" v-if="dropdownItem._id && dropdownItem._id.length > 10">
								{{ dropdownItem._id.slice(3, 9) }}...{{ dropdownItem._id.slice(18, 24) }}
							</span>
						</p>
					</div>
					<div class="PageInput__DropdownItem Page__Flex M_Row" v-if="$slots.addNewLabel && hasAddNewListener" @click="$emit('addNew')">
						<div class="PageInput__DropdownItemIcon addNew Page__FlexNoShrink"></div>
						<p class="PageInput__DropdownItemHeading Page__Text">
							<slot name="addNewLabel"></slot>
						</p>
					</div>
				</div>
			</div>

		</template>


		<template v-if="inputType == 'checkbox'">
			<label class="Page__InputLabel Page__Checkbox">
				<input type="checkbox" :checked="internal_input_value" @click="on_input_value_change($event, 'checkbox')" true-value="true" false-value="false" />
				<span><slot name="label"></slot></span>
			</label>
		</template>


		<template v-if="type == 'textarea'">
			<span><slot name="label"></slot></span>
			<textarea class="Page__Input textarea" id="story" name="story" rows="5"  :placeholder="placeholderText" :value="internal_input_value" @input="on_input_value_change" :maxlength="maxlength">

			</textarea>
		</template>

		<div v-if="inputType == 'richText'">
			<span><slot name="label"></slot></span>
            <TipTap :id="id" :content="internal_input_value" :data="internal_input_value" @editedData="on_input_value_change($event, 'richText')" :basic="basic" :maxlength="maxlength" />
        </div>


		<div v-if="inputType == 'file'">
			<span><slot name="label"></slot></span>
			<div class="Page__Input" @dragover.prevent @drop.prevent>
				<input type="file" class="custom-file-input" 
					@drop="on_drag_drop_file"
					@change="on_input_value_change" multiple>
			</div>

			<div v-if="internal_input_value.length">
				<ul v-for="(file, $index) in internal_input_value" :key="$index">
					<li>{{file.name}}</li>
				</ul>
			</div>
		</div>

		<div class="Page__InputError" v-for="(error, $index) in errors.all()" :key="$index">{{ error }}</div>
	</div>
</template>

<script>
import TipTap from "@/components/forms/RichText/TipTap";

export default
{
	name: "PageInput.vue",
	components: { TipTap },

	data()
	{
		return {
			http_loading: false,
			http_error: null,

			internalInputModel: null,
			dropdownItemSelected: null,
			isInputFocused: false,
			input_width: 0,

			internal_input_value: null,
			validation_state: Boolean,
			ctrl_key_pressed: false,
			searchable_dropdown_http_error: null,
			values_internal: [],
			advanced_upload: false
		}
	},

	props:
	{
		id: String,
		name: String,
		inputModel: null,
		inputType: String,
		placeholderText: null,
		maxlength: Number,
		inputTitle: String,
		initialStatus: Number,
		status: Number, /* 0: none, 1: submittable, 2: checking, 3: success, 4: failure */
		otpState: Number /* 0: none, 1: checking, 2: success, 3: failure */,
		customValidationText: String,
		basicTextLocalModel: String,
		capitalise: Boolean,
		lowercase: Boolean,
		uppercase: Boolean,
		optional: Boolean,
		validate: String,
		disabled: Boolean,
		value: null,
		values: null,
		multiple: Boolean,
		searchEndpoint: String /* to enable api value lookups */,
		autocomplete: null,
        type: null,
		basic: Boolean,
		position: String,

		/* Dropdown Input */
		dropdownItems: Array,
		overrideFocus: Boolean,
		afterSelectionLabel: String,

		/* Number Input */
		min: Number,
		max: Number,

		/* additonal class bindings */
		smd: Boolean,
		padding_size: String,
	},

	model:
	{
		prop: 'inputModel',
		event: 'input'
	},

	beforeMount()
	{
		this.detect_drag_drop()
		this.populate_values()
		this.values_internal = this.values

		this.try_search_dropdown_values()
	},

	methods:
	{
		/*
		 * Detects if browser supports advanced file upload functionality
		 * (has apis for drag and drop)
		 */
		detect_drag_drop()
		{
			var div = document.createElement('div');
  			this.advanced_upload = (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
		},


		/*
		 * Handle File being dragging into the file input
		 */
		on_drag_drop_file(e)
		{
			console.log(e)
		},

		populate_values()
		{
			if (typeof this.inputModel !== 'undefined' && this.inputModel != null)
			{
				if (this.inputType != "searchableDropdown")
					if (this.inputModel.hasOwnProperty('inputModel'))
					{
						this.internal_input_value = this.inputModel.inputModel

						if (this.validate)
						{
							this.reset_validation()
							this.emit_value()
						}
					}
					else
					{
						this.internal_input_value = this.inputModel
						console.log({ type: this.inputType, value: this.inputModel })
						this.emit_value()
					}
				else
				{
					this.internal_input_value = (this.multiple) ? [] : null

					if (this.inputModel.hasOwnProperty('inputModel'))
					{
						if (this.inputModel.inputModel instanceof Array 
							&& this.inputModel.inputModel.length > 0)
						{
							if (this.multiple)
							{
								this.dropdownItemSelected = this.inputModel.inputModel.map((el) => {
									if (el.hasOwnProperty('inputModel'))
									{
										if (el.inputModel.hasOwnProperty('_id'))
											return this.values_internal.find(val => val['_id'] === el.inputModel._id)
										else
											return this.values_internal.find(val => { return val['_id'] === el.inputModel})
									}
								})
							}
							else
							{
								this.dropdownItemSelected = 
									(this.inputModel.inputModel[0].inputModel.hasOwnProperty('_id'))
										? this.values_internal.find(val => val['_id'] === this.inputModel.inputModel[0].inputModel._id)
										: this.values_internal.find(val => { return val['_id'] === this.inputModel.inputModel[0].inputModel })
							}
						}
						else
						{
							if (typeof this.inputModel.inputModel !== 'undefined' && this.inputModel.inputModel !== null)
							{
								this.dropdownItemSelected = this.values_internal.find(val => val['_id'] === this.inputModel.inputModel)
								if (this.dropdownItemSelected)
									this.emit_value()
							}
						}
					}
					else
					{
						if (this.inputModel)
							this.dropdownItemSelected = { _id: this.inputModel }
					}
				}
			}
			else
			{
				this.internal_input_value = ""
			}
		},

		mouse_over_handler($event)
		{
			this.ctrl_key_pressed = $event.ctrlKey
		},


		emit_value(event_type, _log = true)
		{
			if (typeof this.inputModel !== 'undefined' && this.inputModel != null && this.inputType != 'searchableDropdown')
			{
				if (this.inputModel.hasOwnProperty('inputModel'))
				{
					this.reset_validation()

					if (_log) console.log("emit_value: 01_01")

					this.$emit('input', { 
						inputModel: this.internal_input_value, 
						validated: (this.optional && this.internal_input_value.length < 1) ? true : this.validation_state 
					})
					return;
				}
				else
				{
					if (_log) console.log("emit_value: 01_02")
					this.$emit(event_type || 'input', this.internal_input_value)
					return;
				}

			}

			console.log("PageInput.vue Emit Stage 2")

			if (this.inputType == 'searchableDropdown')
			{
				// if multiple
				if (typeof this.inputModel !== 'undefined' && this.inputModel != null && (this.inputModel instanceof Array || this.inputModel.hasOwnProperty('inputModel')))
				{
					if (this.dropdownItemSelected instanceof Array)
						var value = this.dropdownItemSelected.map(a => { return { inputModel: a._id, name: a.name } });
					else var value = (this.dropdownItemSelected && this.dropdownItemSelected._id) 
							? this.dropdownItemSelected._id 
							: null

					this.$emit('input', { 
						inputModel: value,
						name: (this.dropdownItemSelected && this.dropdownItemSelected._id) ? this.dropdownItemSelected.name : "",
						validated: (this.optional && this.internal_input_value.length < 1) 
									? true 
									: this.validation_state 
					})
					this.$emit('changed', value)

					return;
				}
				else
				{
					let value = (this.dropdownItemSelected && this.dropdownItemSelected._id) 
							? this.dropdownItemSelected._id 
							: null

					if (typeof this.inputModel !== 'undefined' && this.inputModel != null && this.inputModel.hasOwnProperty('inputModel'))
						this.$emit('input', { inputModel: value, validated: false })
					else
						this.$emit('input', value)

					this.$emit('changed', [])
					return;
				}
			}

			console.log("event type: " + this.internal_input_value)
			this.$emit('changed', this.internal_input_value)
		},


		reset_validation()
		{
			this.validation_state = (typeof this.internal_input_value != 'undefined')
				&& this.internal_input_value != null
				&& ((typeof this.internal_input_value == 'number') 
						? true
						: this.internal_input_value.length > 0)
				&& this.errors.items.length < 1
		},


		on_input_value_change: function($event, type)
		{
			if (this.inputType == 'radio')
			{
				var value = $event.target.value	
			}
			else if (this.inputType == 'checkbox')
			{
				var value = $event.target.checked
			}
			else if (this.inputType == 'richText')
			{
				// if (this.basic)
				// 	var value = $event.state.doc.textContent
				// else
					var value = $event.getHTML()
			}
			else if (this.inputType == 'file')
			{
				var value = $event.target.files
			}
			// for when needs to manually call this function, instead of using $event
			else if (this.inputType == 'searchableDropdown' && type != 'searchableDropdown')
			{				
				var value = this.internal_input_value
			}
			else
			{
				this.try_search_dropdown_values()
                var value = $event.target.value					
			}


			this.internal_input_value = value
			if (type != 'searchableDropdown')
				this.emit_value()


			if (!this.$listeners.input)
				this.internal_input_value = value

			if (!this.inputType || this.inputType == 'text' || this.inputType == 'number')
			{
				var input = this.$refs.inputBox;
				var c = document.createElement("canvas");
				var ctx = c.getContext("2d");
				ctx.font = this.font(input);
				var txtWidth = ctx.measureText(input.value).width;
				this.input_width = parseInt(txtWidth) + parseInt(window.getComputedStyle(input, null).getPropertyValue('padding-left').replace('px','')) + 10;
			}
		},

		try_search_dropdown_values: async function()
		{
			if (this.searchEndpoint)
			{
				this.http_loading = true
				this.http_error   = null

				let { data, error, status } = await this.request('POST', this.searchEndpoint, {}, 
					{ searchString: this.internal_input_value, _id: (this.dropdownItemSelected) ? this.dropdownItemSelected._id : undefined })
				
				if (error || status !== 200)
				{
					this.searchable_dropdown_http_error = JSON.stringify(error) + " " + data + " " + status
					this.http_error = error
					this.http_loading = false
					return;
				}


				this.values_internal = data || []

				if (this.dropdownItemSelected && this.dropdownItemSelected._id && !this.dropdownItemSelected.name)
					this.dropdownItemSelected = this.values_internal.find(val => val['_id'] === this.dropdownItemSelected._id)

				this.http_loading = false
			}
		},

		font(element) {
			var prop = ["font-style", "font-variant", "font-weight", "font-size", "font-family"];
			var font = "";
			for (var x in prop)
				font += window.getComputedStyle(element, null).getPropertyValue(prop[x]) + " ";

			return font;
		},

		enterHandler: function(data)
		{
			if (this.status == 1)
			{
				this.emit_value('enterEvent')
			}
		},


		onFocusDropdown: function()
		{
			if (this.disabled) return;
			if (this.isInputFocused === false)
			{
				if (!this.multiple)
				{
					this.dropdownItemSelected = null
					this.on_input_value_change(this.dropdownItemSelected)
				}

				this.isInputFocused = true

				this.$nextTick(function() { this.$refs.dropdownSearchInput.focus() })
				this.$emit('onFocus')
			}
		},


		onUnFocusDropdown: function()
		{
			this.$emit('onBlur')
			this.isInputFocused = false
		},


		onDropdownSelectedItem: function(event, keep_focus)
		{
			this.$emit('dropdownItemSelectedEvent', event)

			if (!this.multiple)
			{
				this.dropdownItemSelected = event
				this.on_input_value_change(this.dropdownItemSelected)
			}
			else 
			{
				if (this.dropdownItemSelected && this.dropdownItemSelected instanceof Array)
				{
					if(!this.dropdownItemSelected.some(el => el['_id'] === event._id ))
						this.dropdownItemSelected.push(event)
					else
						this.dropdownItemSelected
							.splice(this.dropdownItemSelected.findIndex(el => el._id === event._id), 1)
				}
				else
					this.dropdownItemSelected = [event]

				this.on_input_value_change(this.dropdownItemSelected)
			}

			if ((keep_focus !== true) && !(this.multiple && this.ctrl_key_pressed === true))
				this.onUnFocusDropdown()
		}
	},


	watch:
	{
		overrideFocus()
		{
			if (this.overrideFocus && !this.isInputFocused) this.onFocusDropdown()
			else if (!this.overrideFocus && this.isInputFocused) this.onUnFocusDropdown()
		},

		values:	{
			deep: true,
			handler: function(newVal, oldVal)
			{
				this.populate_values()
				this.values_internal = this.values
			}
		},

		inputModel: {
			deep: true,
			handler: function (newVal, oldVal)
			{
				console.log({newVal, oldVal })
				if (!newVal)
				{
					this.reset_validation()
					return;
				}

				if (this.inputType !== "searchableDropdown")
				{
					(newVal.hasOwnProperty('inputModel'))
						? this.internal_input_value = newVal.inputModel
						: this.internal_input_value = newVal
				}
				else
				{
					if (newVal.hasOwnProperty('inputModel') && newVal.inputModel instanceof Array && newVal.inputModel.length > 0)
					{
						this.dropdownItemSelected = newVal.inputModel.map((el) => {
							if (el.hasOwnProperty('inputModel'))
							{
								if (el.inputModel.hasOwnProperty('_id'))
									return this.values_internal.find(val => val['_id'] === el.inputModel._id)
								else
									return this.values_internal.find(val => { return val['_id'] === el.inputModel})
							}
						})
					}
					else
					{
						this.dropdownItemSelected = { _id: newVal }
						this.try_search_dropdown_values()
					}
				}

			}
		}
	},

	computed:
	{

		has_label()
		{
			return !!this.$slots.label && !!this.$slots.label[0]
		},

		hasFollowerText() {
			if (!this.$slots.followerText) return false;
			return !!this.$slots.followerText.length
		},
		filteredDropdownItems:
		{
			get()
			{
				if (this.values_internal == undefined)
					return [];

				let resultList = [];
				let none = null

				if (!this.internal_input_value || this.internal_input_value instanceof Array)
					resultList = this.values_internal
				else
				{
					for (let i =0; i < this.values_internal.length; i++) 
					{
						console.log({ toLowerCase: this.internal_input_value })
						if (typeof this.values_internal[i] !== 'undefined' && this.values_internal[i] !== null && this.internal_input_value)
							if (this.values_internal[i].name.toLowerCase().includes(this.internal_input_value.toLowerCase().trim()))
								resultList.push(this.values_internal[i]);

						if (this.values_internal[i]._id == process.env.VUE_APP_PET_NONE)
							none = this.values_internal[i]
					}
				}


				// console.log(process.env.VUE_APP_PET_NONE)
				if (resultList.length < 1 && none)
					resultList.push(none)

				return resultList;
			},

			set() {

			}
		},

		hasAddNewListener()
		{
			return this.$listeners && this.$listeners.addNew
		}
	}
}
</script>

<style lang="scss">

	.Page__InputLabel
	{
		font-weight: bold;
		font-size: 11px;
		margin-left: 2px;
		margin-bottom: 1px;
	}


	.Page__Input
	{
		border: 1px solid #0009;
		padding: 6px 8px;
		display: block;
		width: 100%;
		border-radius: var(--border_radius_main);
		-webkit-transition: all 0.3s;
		transition: all 0.2s;
		color: var(--text_color_secondary);
		background: #fff;
		// font-size: 11px;

		&>input { font-size: 11px; width: 100%; }

		&:focus { color: var(--text_color_main) }
		&:focus, &:hover { box-shadow: $box_shadow_button; }


		&.PageInput__PosLeft
		{
			border-radius: var(--border_radius_main) 0px 0px var(--border_radius_main);
		}

		&.PageInput__PosMid
		{
			border-radius: 0;
			border-left: 0;
		}

		&.PageInput__PosRight
		{
			border-radius: 0px var(--border_radius_main) var(--border_radius_main) 0px;
			border-left: 0;
		}


		&.button { cursor: pointer; }
		&.button.active { border-radius: 0 var(--border_radius_main) 0px 0px; }

		&.PageInput__Disabled 
		{ 
			background: #eee;
    		cursor: no-drop;
    		border-color: #898989;

			&:hover { box-shadow: none; }
		}
	}


	.Page__InputVerticalGroup
	{
		.Page__InputContainer:not(:first-of-type):not(:last-of-type)
		{
			.Page__Input { 
				border-radius: 0;
				border-top: none;
				border-bottom: none;
			}
		}

		.Page__InputContainer:last-of-type
		{
			.Page__Input { border-radius: 0 0 var(--border_radius_main) var(--border_radius_main); }
		}

		.Page__InputContainer:first-of-type
		{
			.Page__Input { border-radius: var(--border_radius_main) var(--border_radius_main) 0 0; }
		}
	}


	.Page__InputsLinked
	{

	}


	.Page__InputContainer
	{
		position: relative;
	}

	.Page__Input_StateContainer
	{
		position: absolute;
		right: 18px;
		top: 52%;
		color: #707173;
	}

	.PageInput__submitText
	{
		color: #676767;
	}

	.PageInput__Loading
	{
		background: #eee;
		cursor: no-drop;
		pointer-events: none;
	}

	.PageInput__submitText.success
	{
		color: green;
	}

	.PageInput__Success
	{
		background: #74a2741c;
	}

	.PageInput__Capitalise { text-transform: capitalize; }
	.PageInput__Uppercase { text-transform: uppercase; }
	.PageInput__Lowercase { text-transform: lowercase; }

    .Page__InputError
    {
        color: var(--background_color_danger);
        font-size: 11px;
        letter-spacing: 0.3px;
        margin-top: 5px;
    }


	.PageInput__DropdownOpen
	{
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.Page__InputDropdownContainer 
	{
		position: relative;

		&:not(.loading_active)::after
		{
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;

			font-family: "Font Awesome 6 Pro";
    		font-weight: 400; 
			font-size: 14px;
			content: "\f0dd";
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-75%);
		}

		&.open::after
		{
			transform: translateY(-28%) rotate(180deg);
		}

		&.hasSelected:not(.open)
		{
			.Page__Input 
			{
				padding: 5px 40px 5px 9px;
			}
		}
	}

	.PageInput__DropdownItemContainer
	{
		position: absolute;
		z-index: 4;
		left: 0;
		right: 0;
		background: var(--background_color_secondary);

		width: 100%;
		border-radius: var(--border_radius_main);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
        border: 1px solid var(--border_color_input);
		border-top: 0;

        background: var(--background_color_secondary);
        font-size: 10px;
        letter-spacing: 0.3px;

		max-height: 190px;
		overflow-y: scroll;

		.PageInput__DropdownItemIcon
		{
			background: var(--background_color_hero);
			height: 18px;
			width: 18px;
			border-radius: 25px;
			margin-right: 7px;
			vertical-align: middle;
			position: relative;
			border: 1px solid var(--color_border_main);

			&.addNew
			{
				&::after
				{
					content: "+";
					position: absolute;
					top: 50%;
					left: 50%;
  					transform: translate(-50%, -50%);
				}
			}

			&.selected
			{
				background: var(--background_color_discord);
			}
		}

		.PageInput__DropdownItem, .Page__DropdownSelectedItemsInner
		{
			padding: 10px 8px;
			vertical-align: middle;
			border-radius: var(--border_radius_main);
			border-top: 1px solid transparent;
			border-bottom: 1px solid transparent;

			cursor: pointer;

			&.no-drop { cursor: no-drop;}

			&:hover
			{
				background: var(--background_color_main);

				border: 1px dashed var(--color_border_main);
				border-left: 0;
				border-right: 0;
				border-radius: 0;
				box-shadow: 3px 4px 0px 0px #75757516;

			}
		}

		.Page__DropdownSelectedItemsInner
		{
			border-radius: 0;
			border-bottom: 1px solid var(--border_color_input);
			gap: 6px;

			.Page__Badge
			{
				padding: 0px 8px;
				font-size: var(--page_text_small);
			}
		}
	}

	.Page__DropdownSelectedItems 
	{
		strong
		{
			font-size: var(--font_size_small);
			text-transform: lowercase;
		}

		.Page__Badge
		{
			padding: 0px 8px;
			font-size: 10px;
		}
	}


	.custom-file-input::-webkit-file-upload-button {
		visibility: hidden;
	}
	.custom-file-input::before {
		content: 'Select some files';
		display: inline-block;
		background: linear-gradient(top, #f9f9f9, #e3e3e3);
		border: 1px solid #999;
		border-radius: 3px;
		padding: 5px 8px;
		outline: none;
		white-space: nowrap;
		-webkit-user-select: none;
		cursor: pointer;
		text-shadow: 1px 1px #fff;
		font-weight: 700;
		font-size: 10pt;
	}
	.custom-file-input:hover::before {
		border-color: black;
	}
	.custom-file-input:active::before {
		background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
	}



	.PageInput__smd 
	{ 
		padding: var(--padding_button_smd);
		padding-top: 1px;
		padding-bottom: 1px;
		font-size: var(--font_size_small);
	}

</style>