<template>
	<div class="App">
		<router-view />
	</div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainSidebar from "@/components/MainSidebar.vue";

export default
{
	name: 'App',
	components: { MainHeader, MainSidebar },

	beforeMount()
	{
		this.$store.commit('setHeader', { name: 'x-access-key',  content: this.$store.state.headers['x-access-key']  })
		this.$store.commit('setHeader', { name: 'Authorization',  content: this.$store.state.headers['x-access-key']  })
		this.$store.commit('setHeader', { name: 'x-user-tenant', content: this.$store.state.headers['x-user-tenant'] })
	},

	mounted()
	{
		let vh = window.innerHeight * 0.01;
		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}
}
</script>

<style lang="scss">
	.App
	{
		background: var(--background_color_main);

		/* Height set to take full viewport, only RouterView should scroll */
		height: 100vh;
		width: 100vw;

		display: flex;
		overflow: hidden;
	}

	.App__ViewPortion
	{
		flex: 1;
	}

	.App__MainView
	{
		border-radius: $border_radius_main 0px 0px 0px;
		//background: $color_background_secondary;

		padding: $padding_panel_main_inner;

		@media (max-width: $media_query_mobile)
		{
			padding: 15px;
		}
	}


	.App__Panel
	{
		padding: $padding_panel_main_inner;
		background: var(--background_color_main);

		.App__PanelHeading
		{

		}
	}
</style>
