import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

export default new Vuex.Store({
	state: {
		count: 0,
		user: null,
		headers: {}
	},
	mutations: {
		setUser (state, user) { state.user = user },
		setHeader (state, header) { state.headers[header.name] = header.content }
	},
    plugins: [vuexLocal.plugin]
})