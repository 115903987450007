<template>
	<div :is="type || 'button'" type="button" class="ib" v-bind:onLoading="testVar" v-on:click.stop="onClick"
	     :class="{ 'success': status == 4, 'danger': status == 3  }" :disabled="status != 0 && status != 4">
		<transition name="fade">
			<span>
				<slot name="default" v-if="status == 0">Button</slot>
			</span>
		</transition>
		<transition name="fade">
			<span>
				<slot name="loading" v-if="status == 1">Loading <i class="ib-icon fas fa-circle-notch fa-spin"></i></slot>
			</span>
		</transition>
		<slot name="warning" v-if="status == 2">Warning <i class="ib-icon fas fa-exclamation-triangle"></i></slot>
		<slot name="error" v-if="status == 3">Error <i class="ib-icon fas fa-exclamation-circle"></i></slot>
		<slot name="error" v-if="status == 4">Success <i class="ib-icon far fa-check-circle"></i></slot>
	</div>
</template>

<script>
export default
{
	name: "InteractiveButton",
	data()
	{
		return {
			testVar: "aaaaaa",
			status: 0
		}
	},
	props:
	{
		type: String
	},
	methods:
	{
		onClick()
		{
			console.log('clicked')
			this.$emit("clicked", this)
		},
		onDefault()
		{
			this.status = 0
		},
		onLoading()
		{
			this.status = 1
		},
		onWarning()
		{
			this.status = 2
		},
		onError()
		{
			this.status = 3
		},
		onSuccess()
		{
			this.status = 4
		},
	}
}
</script>

<style scoped lang="scss">

	.ib {
		position: relative;

		.ib-icon {
			position: absolute;
			right: 15px;
		}

		.fade-enter-active, .fade-leave-active {
			transition: opacity 1s;
		}

		.fade-enter, .fade-leave-to, .fade-leave-active {
			opacity: 0;
		}

	}



</style>
