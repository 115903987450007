












export default
{
	name: "CustomerCard",
	props:
		{
			customer:
				{
					name: String
				}
		}
}
