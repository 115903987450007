

export const calculateElementSize =
{
	methods:
	{
        $calculate_el_height(el)
        {
            if (el)
            {
                // Get the computed style for the div
                const computedStyle = getComputedStyle(el);

                // Extract the values for padding, margin, and border
                const paddingTop = parseFloat(computedStyle.paddingTop);
                const paddingBottom = parseFloat(computedStyle.paddingBottom);
                const marginTop = parseFloat(computedStyle.marginTop);
                const marginBottom = parseFloat(computedStyle.marginBottom);
                const borderTopWidth = parseFloat(computedStyle.borderTopWidth);
                const borderBottomWidth = parseFloat(computedStyle.borderBottomWidth);

                // Calculate the full height
                let fullHeight =
                el.clientHeight +
                paddingTop + paddingBottom +
                marginTop + marginBottom +
                borderTopWidth + borderBottomWidth;

                return fullHeight
            }
            else { console.warn("el not provided for height calc"); return -1; }
        }
	}
}