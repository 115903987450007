













import CustomerCard from "@/components/dashboard/CustomerCard.vue";

export default
{
	name: "MainHeader",
	components: { CustomerCard },
	data()
	{
		return {
			customer: { name: "Customer Name" },
		}
	}
}
