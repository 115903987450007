<template>
	<div class="MainSidebarContainer">
		<div class="App__LayoutButtons">
			<div class="MainSidebar__Section MainSidebar__Links Page__Flex column">
				<div class="App__LogoMain MarginBottom_x5 Page__Flex AlignVerticalMiddle M_AlignFlexStart">
					<div class="Page__FlexItem">
						<h4 class="ClientArea__TenantLogo">
							{{ selected_tenant.tenant_name }}
							<div class="ClientArea__TenantSwitch MarginLeft_x1" @click="SwitchTenantMenu"><i class="fa-solid fa-arrows-repeat"></i></div>
						</h4>
						<p class="ClientArea__CorporateLogo">contractor financial</p>
					</div>
				</div>

				{{http_error}}

				<div v-if="switch_tenant_menu_open" class="MarginBottom_x7">
					<div class="App__LayoutButton" @click="SelectTenant(org._id)" v-for="org in user_organisations" :key="org._id">
						<i class="fa-solid fa-fw MarginRight_x1" style="font-size: 12px" v-bind:class="{ 'fa-circle-check': selected_tenant._id == org._id, 'fa-circle-dashed': selected_tenant._id != org._id}"></i> {{ org.tenant_name }}
					</div>
				</div>

				<router-link class="App__LayoutButton" :to="{ name: 'Dashboard' }">
					<i class="fa-regular fa-gauge-simple fa-fw MarginRight_x1" style="font-size: 12px"></i>Home
				</router-link>
				<router-link class="App__LayoutButton" :to="{ name: 'Documents' }">
					<i class="fa-regular fa-file-invoice-dollar fa-fw MarginRight_x1" style="font-size: 12px"></i>Documents
				</router-link>
				<router-link class="App__LayoutButton" :to="{ name: 'Customers' }">
					<i class="fa-regular fa-user fa-fw MarginRight_x1" style="font-size: 12px"></i>Customers
				</router-link>
				<router-link class="App__LayoutButton" :to="{ name: 'Financial' }">
					<i class="fa-regular fa-file-chart-column fa-fw MarginRight_x1" style="font-size: 12px"></i>Financial
				</router-link>
				<router-link class="App__LayoutButton" :to="{ name: 'Projects' }">
					<i class="fa-regular fa-projector fa-fw MarginRight_x1" style="font-size: 12px"></i>Projects
				</router-link>
				<router-link class="App__LayoutButton" :to="{ name: 'Receipts' }">
					<i class="fa-light fa-receipt fa-fw MarginRight_x1" style="font-size: 12px"></i>Receipts
				</router-link>
				<router-link class="App__LayoutButton" :to="{ name: 'DesignGuide' }">
					<i class="fa-regular fa-ruler-triangle fa-fw MarginRight_x1" style="font-size: 12px"></i>Design Guide
				</router-link>

				<div class="Page__FlexItem"></div>
				<div class="App__LogoMain user MarginBottom_x0 AlignVerticalMiddle Page__Flex M_Row MarginTop_x6 Gap_x2">
					<div class="ClientArea__TenantImage"><i class="fa-regular fa-user"></i></div>
					<div>
						<h6 class="ClientArea__TenantLogo">{{ user.full_name }}</h6>
						<router-link :to="{ name: 'UserSettings' }" class="ClientArea__CorporateLgoo">Settings</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ReactiveButton from '@/components/forms/ReactiveButton'

	export default
	{
		name: "MainSidebar",
		components: { ReactiveButton },
		data()
		{
			return {
				switch_tenant_menu_open: false,
				http_error: null
			}
		},

		methods:
		{
			SwitchTenantMenu: function()
			{
				this.switch_tenant_menu_open = !this.switch_tenant_menu_open
			},

			SelectTenant: async function(_id)
			{
				let { data, error, status } = await this.request('GET', '/account/select-tenant/' + _id)
				if (error || status != 200)
				{
					this.http_error = error + " " + data + " " + status
					return
				}

				console.log(data)
				this.$store.commit('setUser', data)
				this.$store.commit('setHeader', { name: 'x-user-tenant', content: "Tenant " + data.selected_organisation })

				this.$router.go()
			}
		},

		computed:
		{
			selected_tenant: function()
			{
				return this.$store.state.user.user_organisations.find(o => o._id === this.$store.state.user.selected_organisation);
			},

			user_organisations: function()
			{
				return this.$store.state.user.user_organisations
			},

			user: function()
			{
				return this.$store.state.user
			}
		}
	}
</script>

<style scoped lang="scss">

	.MainSidebar, .MainSidebar__TopBar
	{
		width: 250px;

		background: var(--background_color_main);
		padding: 0 0;
	}

	.MainSidebarContainer
	{
		// height: 100vh;
		// disabled above because it was pushing down content on mobile when content is not full height
		max-height: 100vh;
	}

	.MainSidebar__TopBar
	{
		padding: 0px $padding_panel_main_inner;
		height: $header_height;
	}




	.App__LayoutButtons
	{
		//border-right: 1px solid $client-area-color-border;
		width: 200px;
		display: flex;
		flex-direction: column;
		padding: 55px 35px;

		@media (max-width: 700px)
		{
			width: 100%; 
			padding: 25px;
			// padding-bottom: 0;

			background-color: #e7e4e4;
			border-bottom: 1px solid #adadad66;
		}

		@media (min-width: 701px)
		{
			border-radius: 0;
			margin: 0;
			margin-right: 0;
			height: calc(100% - 0px);
			/* border-right: 1px solid #6654bf; */
			background: #dfdfdf;
			font-weight: 400;
			color: #fffffff2;
			padding: 0;
			border-right: 1px solid #777;
			box-shadow: 1px 0px 14px #45464b1f
		}
		
		.App__LayoutButton
		{
			cursor: pointer;
			display: block;
			border: none;
			padding: 7px 10px;
			margin: 0px -10px;
			border-radius: $border_radius_main;
			font-weight: bold;
			text-transform: lowercase;
			font-weight: 400;
			text-decoration: none;
			color: #000;

			@media (min-width: $media_query_mobile)
			{
				margin: 5px 15px;
				color: #0c2955;
				background: #eeeeee5e;
				text-decoration: underline;

				padding: 10px 10px;
				margin: 5px 15px;
			}
			
			flex-shrink: 0;
			flex-grow: 0;
			//border-bottom: 1px solid $client-area-color-border;

			&>i
			{
				font-size: 15px;
				margin-right: 9px;
			}

			&:hover
			{
				background: #c5c5c57a;
			}

			&.router-link-exact-active
			{
				background: #c5c5c57a;
			}
		}

		.App__LayoutButtonsSpacer
		{
			flex: 1;
		}

		.ClientArea__Footer
		{
			padding: 15px 20px;
			color: #0007;
		}
	}



	.ClientArea__TenantLogo
	{
		@media (max-width: $media_query_mobile)
		{
			margin: 0;
			line-height: initial;
			margin-bottom: -2px;
		}

		@media (min-width: $media_query_mobile)
		{
			color: var(--text_color_main);
		}
	}

	.ClientArea__CorporateLogo
	{
		color: var(--text_color_main_lighten);
	}


	.App__LogoMain
	{

		@media (min-width: $media_query_mobile)
		{
			border-bottom: 1px solid #777;
			padding: 15px 25px;

			border-bottom: 1px solid var(--color_border_main);
			background: var(--background_color_secondary);
			color: var(--text_color_main);
		}

		.ClientArea__TenantSwitch
		{
			color: #01f;
			background: #dbdbdb;
			padding: 2px 4px;
			border-radius: 8px;

			display: inline-block;
			@media (min-width: $media_query_mobile)
			{
				// color: var(--text_color_invert);
			}
		}


		&.user
		{
			margin: 0px -10px;
			margin-top: 20px;
			
			background: #c5c5c57a;
			border-radius: 0px;
			padding: 5px;

			a { font-size: 0.95em; }


			.ClientArea__TenantImage
			{
				border-radius: 25px;
				height: 35px;
				width: 35px;
				background: var(--background_color_sidebar);
    			border: 1px solid var(--background_color_success);


				position: relative;


				@media (max-width: $media_query_mobile)
				{
					height: 40px;
					width: 40px;
				}

				*
				{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}


			@media (min-width: $media_query_mobile) 
			{
				margin: 0;
				padding: 15px 15px;
				border-top: 1px solid var(--color_border_main);
				background: var(--background_color_secondary);
				color: var(--text_color_main);
			}
		}
	}
</style>