<template>
  <div class="SearchDropdown" v-clicked-outside="onUnfocus" @click="on_focus" v-bind:class="{ 'is_opened': (true && focused == true) }">
	  <input type="text" class="SearchDropdown__Input" ref="input" placeholder="Search" v-model="searchTerm" @focus="on_focus" @keydown="searchCustomer" v-bind:class="{ 'SearchDropdown__InputOpened': (searchTerm && focused) }" />

	  <div class="results" v-show="searchTerm && focused">
		  <h6 class="MarginBottom_x2">Customers</h6>
<!--		  Searching-->
		  <div class="result MarginTop_x2" v-for="result in results">
			  <div class="result__customerIcon MarginRight_x1"><i class="far fa-user"></i></div>
			  <div>
				  <h6 class="result__customerName">{{ result.name }}</h6>
				  <p class="result__customerAddress">{{ result.address_one }}, {{ result.address_two }}, {{ result.address_three }}</p>
			  </div>
		  </div>
		  <p v-if="results.length == 0">No Customers Found</p>
	  </div>
  </div>
</template>

<script>
import {debounce} from "debounce";
import {HTTPRequest} from "@/mixins/HTTPRequest";

export default
{
  	name: "SearchDropdown",
	mixins: [HTTPRequest],
	data()
	{
		return {
			results: {},
			searchErrors: [],
			searchTerm: "",
			focused: false
		}
	},

	methods:
	{
		searchCustomer: debounce(async function(e)
		{
			let { data: data, error: error } = await this.request('POST', '/search/customers', {}, { 'searchString': this.searchTerm })
			if (error)
			{
				this.searchErrors = error
			}
			else
			{
				console.log("A: " + data)
				this.results = data
			}
		}, 500),

		onUnfocus: function()
		{
			this.focused = false
		},


		on_focus: function($evt)
		{
			this.focused = true
			this.$nextTick(() => {
				this.$forceUpdate()
				this.$refs.input.focus()
			})
		}
	}
}
</script>

<style scoped lang="scss">
	.SearchDropdown
	{
		font-size: 15px;
		transition: all 0.2s;
		position: relative;

		&:hover
		{
			border-color: #aeaed9;
		}

		&>input
		{
			padding: 10px 15px;
			font-family: inherit;
			font-size: inherit;
			text-transform: capitalize;
			width: 100%;
			border: 1px solid #1d1c222e;
			border-radius: 6px;
			font-size: var(--font_size_main);
			//border-bottom: 1px solid #1d1c222e;
		}

		&.is_opened
		{
			position: relative;
			max-width: 250px;
			width: 250px;
			left: calc(-250px + 100%);
			z-index: 1;
			background: #eee;
			box-shadow: 0 0 2px 9px #efefef;
			border-radius: 4px;
		}

		&>.SearchDropdown__InputOpened
		{
			border-radius: 6px 6px 0px 0px;
		}

		&>.results
		{
			position: absolute;
			z-index: 2;
			width: 100%;
			padding: 12px 15px;
			font-size: 13px;
			background: #f8f8fd;
			height: 200px;
			overflow-y: scroll;
			border: 1px solid #1d1c222e;
			border-top: 0;
			border-radius: 0px 0px 6px 6px;

			&>.result
			{
				//background: #fff;
				border-radius: 6px;
				padding: 6px 0;
				display: flex;

				&>.result__customerIcon
				{
					border-radius: 25px;
					height: 35px;
					width: 35px;
					background: #fff;
					position: relative;

					&>i
					{
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						font-size: 16px;
					}
				}

				&>div>.result__customerName
				{
					font-weight: bold;
					margin-bottom: 2px;
					display: block;
				}

				&>div>.result__customerAddress
				{
					//font-size: 14px;
				}
			}
		}
	}
</style>