import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from "./router.js";
import store from './store/index'

import "@/assets/css/global.scss";
import "@/assets/css/shared.scss";


import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import VeeValidate from 'vee-validate';

import '@/mixins/DropdownClickDirective'
import "@/assets/fa/css/all.min.css"

Vue.config.ignoredElements = ['h7', 'h8', 'h9', 'h10']


// axios.interceptors.request.use(
// 	config => {
// 		const token = localStorage.getItem('authToken');
// 		const auth = token ? `Bearer ${token}` : '';
// 		config.headers.common['Authorization'] = auth;
// 		config.headers.common['x-user-tenant'] = "Tenant 5fba6fd71aed09068058501b";
// 		return config;
// 	},
// 	error => Promise.reject(error),
// );


Vue.mixin({

	data()
	{
		return {
			$editorDataParsed: null
		}
	},

	beforeMount()
	{
		this.$set_editor_data()
	},

	methods:
	{
        $set_editor_data()
        {
			if (!this.$route || !this.$route.query) return;
            if (this.$route.query.editorData)
            {
                this.$editorDataParsed = this.query_to_object(this.$route.query.editorData)
            }
        },

		object_to_query(data)
		{
			return JSON.stringify(data)
			// return Object.keys(data).map(key => {
			//   let val = data[key]
			//   if (val !== null && typeof val === 'object') val = createQueryString(val)
			//   return `${key}=${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}`
			// }).join('&')
		},
		
		query_to_object(data)
		{
			try {
				var testIfJson = JSON.parse(data);
				if (typeof testIfJson == "object"){
					return testIfJson;
				} else {
					return data
				}
			}
			catch {
				return data;
			}
		}
	},

    watch:
    {
        '$route.editorData': function(old, newVal)
        {
            this.$set_editor_data()
        }
    }
})


Vue.use(axios)
Vue.use(VueAxios, axios)
Vue.use(VeeValidate);

Vue.filter('formatDate', function(value) {
	if (value) {
		return moment(String(value)).format('DD/MM/YY hh:mm')
	}
});

import { HTTPRequest } from "@/mixins/HTTPRequest"
import { calculateElementSize } from "@/mixins/calculateElementSize.mixin"
import FormInput from "@/components/forms/FormInput"
import PageInput from "@/components/forms/PageInput"
import ReactiveButton from "@/components/forms/ReactiveButton"
import InteractiveButton from "@/components/forms/Buttons/InteractiveButton"

Vue.mixin(HTTPRequest)
Vue.mixin(calculateElementSize)
Vue.component('FormInput', FormInput)
Vue.component('PageInput', PageInput)
Vue.component('ReactiveButton', ReactiveButton)
Vue.component('InteractiveButton', InteractiveButton)

new Vue({
	router: router,
	store,
	render: h => h(App)
}).$mount('#app')