<template>
	<div class="EditDocument">
		<div class="EditDocument__Overlay" v-if="!loadingFinished">{{ loadingDataState }}</div>

		<!-- Document Basic Info -->
		<div class="Page__FloatingPanel" v-if="m_document">
			<h5 class="App__PanelHeading">
				Edit Document
			</h5>

			<table class="Page__Table MarginTop_x3">
				<tr class="editable">
					<td>name:</td>
					<td class="editable">#{{ m_document.name }}</td>
				</tr>
				<tr>
					<td>created at:</td>
					<td>{{ moment(m_document.created_at).format('DD-MM-YYYY @ HH:mm') }}</td>
				</tr>
				<tr>
					<td>last edit:</td>
					<td>{{ moment(m_document.updated_at).format('DD-MM-YYYY @ HH:mm') }}</td>
				</tr>
				<tr v-if="!m_document.is_draft">
					<td>views:</td>
					<td v-if="m_document._view_data_error">{{ m_document.view_data_error }}</td>
					<td v-else-if="m_document._view_data !== undefined"><i class="fa-solid fa-eye" style="font-size: 0.75em"></i>&nbsp; {{ m_document._view_data }}</td>
					<td v-else><i class="fa-duotone fa-spinner-third fa-spin"  style="font-size: 0.75em"></i>&nbsp; Loading..</td>
				</tr>
			</table>


			<div class="EditDocument__Indicators MarginTop_x3" v-if="m_document">
				<div class="Page__Badge warning MarginRight_x2" v-if="m_document.is_draft">					
					Draft
				</div>
				<div class="Page__Badge success MarginRight_x2" v-if="!m_document.is_draft">
					<i class="fa-regular fa-check-circle MarginRight_x1"></i>Sent To Customer
				</div>
				<div class="Page__Badge success MarginRight_x2" v-if="isDocumentSaved">					
					<i class="fa-regular fa-cloud MarginRight_x1"></i>Saved
				</div>
				<div class="Page__Badge MarginRight_x2" v-if="!isDocumentSaved">					
					<i class="fa-regular fa-cloud MarginRight_x1"></i>Saving...
				</div>
				<div class="Page__Badge" v-if="!m_document.is_draft">					
					<i class="fa-regular fa-clock MarginRight_x1"></i>Awaiting Payment
				</div>
			</div>
		</div>


		<!-- Customer Details -->
		<div class="Page__FloatingPanel MarginTop_x6" v-if="m_document">
			<h5 class="App__PanelHeading">
				Customer Details
			</h5>

			<div class="Page__Alert MarginTop_x1" v-if="!m_document.is_draft">
				<p class="Page__Text small">
					Note: can't change customer or project as document has already been sent -
					create new document or make a copy of this one to reasign customer or project.
				</p>
			</div>


			<PageInput class="MarginTop_x3"
				inputType="searchableDropdown"
				placeholderText="Select Customer"
				searchEndpoint="search/customers/"
				@addNew="add_new_customer"
				v-model="m_document.customer"
				:disabled="!m_document.is_draft">
				<template slot="label">Customer</template>
				<template slot="addNewLabel">Add New Customer</template>
			</PageInput>

			<PageInput class="MarginTop_x3"
				inputType="searchableDropdown"
				placeholderText="Select Customer Project"
				:searchEndpoint="'search/customer/' + ((m_document.customer._id) ? m_document.customer._id : m_document.customer) + '/projects'"
				@addNew="add_new_project"
				v-model="m_document.project"
				v-if="m_document.customer"
				:disabled="!m_document.is_draft">
				<template slot="label">Project</template>
				<template slot="addNewLabel">Create New Project</template>
			</PageInput>


			<!-- <div class="MarginTop_x9">
				<h8 class="Page__Heading">Attachment Settings</h8>

				<PageInput class="MarginTop_x3" :inputType="'checkbox'" :id="'use_generated_total'" v-model="m_document.use_generated_total">
					<template slot="label">Show in customer portal</template>
				</PageInput>
				<PageInput class="MarginTop_x1" :inputType="'checkbox'" :id="'use_generated_total'" v-model="m_document.use_generated_total">
					<template slot="label">Counts towards project spend</template>
				</PageInput>
			</div> -->
		</div>


		<!-- Services -->
		<div class="Page__FloatingPanel MarginTop_x6" v-if="m_document">
			<h5 class="App__PanelHeading">
				Services and Goods
			</h5>

			<div class="EditDocument__Service Page__Flex AlignFlexEnd M_Row  App__VerticalContent" v-for="(field, index) in m_document.fields">
				<PageInput class="MarginTop_x3 Page__FlexItem"
					inputType="text"
					placeholderText="Kitchen Fitting"
					v-model="field.description"
					position="left">
					<template slot="label">Service</template>
				</PageInput>
				<PageInput class="MarginTop_x3" style="max-width: 80px;"
					inputType="text"
					placeholderText="10"
					v-model="field.price"
					position="middle">
					<template slot="label">Cost</template>
				</PageInput>
				<PageInput class="MarginTop_x3" style="max-width: 80px;"
					inputType="button"
					position="right" @click.native="onDeleteRow(index)">
					<template slot="content"><i class="fas fa-trash" style="color: #bf5353"></i></template>
				</PageInput>


			</div>

			<ReactiveButton class="MarginTop_x4" style="width: 100%;" btn-type="basic" @click="onAddRow">
				<template v-slot:initial >
					Add Row <i class="fas fa-plus"></i>
				</template>
			</ReactiveButton>
		</div>

		<div class="Page__FloatingPanel MarginTop_x6" v-if="m_document">
			<h5 class="App__PanelHeading">
				Document Type
			</h5>

			<div class="Page__Flex Column M_Column Gap_x1">
				<div v-for="choice in document_type_choices" :key="choice._id">
					<page-input input-type="radio" :id="'editdocumenttype'" :value="choice.name" v-model="m_document.document_type">
						<template slot="label">{{ choice.name }}</template>
					</page-input>
				</div>
			</div>
		</div>


		<div class="Page__FloatingPanel MarginTop_x6" v-if="m_document">
			<h5 class="App__PanelHeading">
				Other Info
			</h5>

			<PageInput class="MarginTop_x7" inputType="richText" :basic=true id="notes" v-model="m_document.customer_notes">
				<template slot="label">Notes To Customer</template>
			</PageInput>


			<div class="MarginTop_x7">
				<FormInput class="MarginBottom_x3 App__FlexItem" placeholder-text="0000.00" :value.sync="m_document.total_amount">
					<template v-slot:label>Total Amount</template>
				</FormInput>
				
				<input type="checkbox" id="jack" :value="true" v-model="m_document.use_generated_total">
				<label for="jack">Use Generated Total</label><br/>
				generated amount: {{ m_document.use_generated_total }}
			</div>

		</div>

		<!-- Payment and Other Info -->
		<div class="Page__FloatingPanel MarginTop_x6" v-if="m_document">
			<h5 class="App__PanelHeading">
				More Details
			</h5>

			<div class="Page__Alert warning MarginTop_x6" v-if="http_error">
				{{ http_error }}
			</div>

			<div class="Page__Flex Gap_x2 MarginTop_x2">
				<interactive-button class="Page__Button outline Page__FlexItem" ref="saveButton" @clicked="saveDocument">
						Save <i class="fas fa-save"></i>
				</interactive-button>
				<!-- <interactive-button class="Page__Button outline" ref="saveBtn" @clicked="onSealAndSend">
						Download <i class="fas fa-arrow-down"></i>
				</interactive-button> -->
				<interactive-button class="Page__Button Page__FlexItem" ref="sealBtn" @clicked="onSealAndSend" :disabled="!isDocumentSaved || !m_document.is_draft">
						Seal and Send <i class="fas fa-rocket"></i>
				</interactive-button>
			</div>
		</div>
	</div>
</template>

<script>
import ReactiveButton from "@/components/forms/ReactiveButton";
import FormInput from "@/components/forms/FormInput";
import { debounce } from "debounce";
import SearchDropdown from "@/components/forms/SearchDropdown";
import moment from 'moment'
import { EventBus } from '@/event-bus';
import Vue from 'vue'

export default
{
	name: "EditDocument",
	components: {SearchDropdown, FormInput, ReactiveButton},
	props: [ '_id', 'isDocumentViewer', 'editorData' ],
	data()
	{
		return {
			moment: moment,

			document_type_choices: [
				{ _id: 0, name: "Quote" },
				{ _id: 1, name: "Invoice" },
				{ _id: 2, name: "Receipt" },
				{ _id: 3, name: "Credit Note" },
			],

			m_document: {
				customer: "",
				fields: [
					{ description: "dssdhf", price: 10 },
					{ description: "dssdhf", price: 10 },
				],
			},

			initialLoad: true,
			isDocumentSaved: true,
			loadingDataState: "Loading Document... ",
			loadingFinished: false,

			customerSearchInput: "",
			foundCustomers: {},
			
			http_error: null,
			data: null
		}
	},

	watch:
	{
		m_document:
		{
			handler: function (newVal)
			{
				if (this.m_document.use_generated_total)
				{
					this.m_document.total_amount = this.m_document.fields.reduce(this.combineArray, 0);
				}

				if (this.initialLoad)
				{
					this.initialLoad = false
					return
				}

				this.isDocumentSaved = false
				this.saveDocument()
			},
			deep: true
		},

		'$route.query.editorData': function() 
		{
			this.load_document()
			this.$forceUpdate();
			this.$nextTick(() => this.$forceUpdate())
		}
	},

	async beforeMount()
	{
		EventBus.$on('added_customer', this.added_customer);
		this.load_document()
	},

	methods:
	{
		load_document: async function()
		{
			let _id = (this.$route.name == "EditDocument") ? this.$route.params._id : this.editorData
			let {data, error, status} = await this.request('GET', process.env.VUE_APP_API_URL + 'documents/' + _id)

			if (status != 200 || error)
			{
				this.loadingDataState = error + " " + JSON.stringify(data) + " " + status
			} else {

				console.log({ data })

				this.m_document = data
				this.loadingFinished = true

				if (!this.m_document.customer_notes) Vue.set(this.m_document, 'customer_notes', "")
				if (!this.m_document.document_type) Vue.set(this.m_document, 'document_type', "")
				console.log(this.m_document)

				if (this.m_document._id != _id)
				{
					if (this.$route.name == "EditDocument")
					{
						this.$router.push({
							params: {
								_id: this.m_document._id,
								// you can pass any value you want here.
							}
						});
					}
					else 
					{
						this.$router.push({
							query: {
								editorData: this.m_document._id,
							}
						});
					}

				}

				if (!this.m_document.is_draft)
				{
					let {data, error, status} = await this.request('GET', process.env.VUE_APP_API_URL + 'documents/' + _id + '/attachment_views')
					if (error || status != 200) { this.m_document._view_data_error = error || status; return; }
					this.m_document._view_data = data.count

					this.$nextTick(() => this.$forceUpdate())
				}

			}
		},

		added_customer: function(value)
		{
			console.log({ added_customer: value })
			this.m_document.customer = value._id
		},

		add_new_customer()
		{
			this.$router.push({ name: '', query: { editor: "AddCustomer" } })
		},

		add_new_project()
		{
			this.$router.push({ name: '', query: { editor: "CreateProject", editorData: this.object_to_query({ _id: this.m_document.customer}) } })
		},

		selectCustomer(id)
		{
			console.log(id)
			this.m_document.customer = id
			console.log(this.m_document)
		},

		searchCustomer: debounce(async function(e)
		{
			console.log("YES")
			let result = await this.axios.post(process.env.VUE_APP_API_URL + 'search/customers/', { searchString: this.customerSearchInput })
			console.log(result)
			if (result.data.status == 200)
			{
				this.foundCustomers = result.data.content
			}
		}, 500),

		saveDocument: async function($evt)
		{
			if (this.$refs.saveButton) this.$refs.saveButton.onLoading()
			await this.saveDocumentApi()
		},


		saveDocumentApi: debounce(async function()
		{
			if (this.$refs.saveButton) this.$refs.saveButton.onLoading()
			let _id = (this.$route.name == "EditDocument") ? this.$route.params._id : this.editorData

			let result = await this.axios.post(process.env.VUE_APP_API_URL + 'documents/' + _id, this.m_document)
			if (result.data.status != 200)
			{
				this.loadingFinished = false
				this.loadingDataState = result.data.content.message
			}
			else
			{
				this.loadingFinished = true
				this.isDocumentSaved = true

				if (this.$refs.saveButton)
				{
					this.$refs.saveButton.onSuccess()
					setTimeout(() => this.$refs.saveButton.onDefault(), 1500);
				}

				return true;
			}
		}, 3000),


		onSealAndSend: async function()
		{
			if (this.$refs.sealBtn)
				this.$refs.sealBtn.onLoading()

			let {data, error, status} = await this.request('POST', '/projects/files/upload/invoice', {}, { 
					document: this.m_document._id, 
					project: this.m_document.project, 
					customer: this.m_document.customer 
				})

			if (error || status != 200)
			{
				this.http_error = JSON.stringify(error) + " " + data + " " + status
				this.data = null
				return
			}

			if (this.$refs.sealBtn)
				this.$refs.sealBtn.onSuccess()
			this.m_document = data
		},

		onDeleteRow: function(index)
		{
			this.m_document.fields.splice(index, 1);
		},

		onAddRow: function()
		{
			this.m_document.fields.push({ description: "service", price: 10 })
		},

		combineArray: function(accumulator, a)
		{
			if (isNaN(parseFloat(a.price)))
				return accumulator;
			let val = (parseFloat(a.price) + parseFloat(accumulator))
			return val.toFixed(2);
		},
	}
}
</script>

<style scoped lang="scss">
	@import url(~@/assets/css/radio.scss);

	.EditDocument
	{
		position: relative;
	}

	.EditDocument__Overlay
	{
		background: #fff;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 2;
		text-align: center;
	}

	.EditDocument
	{
		max-width: 500px;
	}
</style>