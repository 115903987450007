import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import store from './store/index'
import VueAxios from 'vue-axios'

import { EventBus } from '@/event-bus';

Vue.use(Router)
Vue.use(VueAxios, axios)

import EditDocument from "@/views/Documents/EditDocument.vue";
import VueRouter from "vue-router";

const Home              = () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue')
const Dashboard         = () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard.vue')
const Documents         = () => import(/* webpackChunkName: "Documents" */ '@/views/Documents/Documents.vue')
const Customers         = () => import(/* webpackChunkName: "Customers" */ '@/views/Customers/Customers.vue')
const Customer          = () => import(/* webpackChunkName: "Customer" */ '@/views/Customers/Customer.vue')
const DesignGuide       = () => import(/* webpackChunkName: "Customers" */ '@/views/DesignGuide.vue')
const Login             = () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue')
const Projects          = () => import(/* webpackChunkName: "Login" */ '@/views/Projects/Projects.vue')
const Project           = () => import(/* webpackChunkName: "Login" */ '@/views/Projects/Project.vue')
const Receipts          = () => import(/* webpackChunkName: "Receipts" */ '@/views/Receipts.vue')
const UploadReceipt     = () => import(/* webpackChunkName: "UploadReceipt" */ '@/views/Financial/UploadReceipt.vue')
const Authentication    = () => import(/* webpackChunkName: "Authentication" */ '@/views/users/Authentication.vue')
const FinancialOverview = () => import(/* webpackChunkName: "FinancialOverview" */ '@/views/Financial/FinancialOverview.vue')
const Vehicles = () => import(/* webpackChunkName: "Vehicles" */ '@/views/Vehicles/Vehicles.vue')
const UserSettings      = () => import(/* webpackChunkName: "UserSettings" */ '@/views/users/UserSettings.vue')

const ClientArea = () => import(/* webpackChunkName: "ClientArea" */ '@/views/ClientArea/ClientArea.vue')
const ClientArea_Home = () => import(/* webpackChunkName: "ClientArea_Home" */ '@/views/ClientArea/Home.vue')
const ClientArea_Documents = () => import(/* ClientArea_Documents: "ClientArea_Documents" */ '@/views/ClientArea/Documents/Documents.vue')
const ClientArea_Document = () => import(/* ClientArea_Documents: "ClientArea_Document" */'@/views/ClientArea/Documents/Document')
const ClientArea_Schedule = () => import(/* ClientArea_Schedule: "ClientArea_Schedule" */ '@/views/ClientArea/Schedule/Schedule.vue')



function has_query_params(route) 
{
	return !!Object.keys(route.query).length
}


async function route_guard(to, from, next)
{
	for (const key in store.state.headers)
		axios.defaults.headers.common[key] = store.state.headers[key]

	try
	{
		var result = await axios.get(process.env.VUE_APP_API_URL + 'auth/verify',)
	}
	catch (error)
	{
		console.log("router.js axios error: " + error)
		EventBus.$emit('onNetworkState', { state: "error", error: error })

		next('/account/login');
		return;
	}

	EventBus.$emit('onNetworkState', { state: "online" })

	if(result.data.status == 200)
	{
		// allow to enter route
		store.commit('setUser', result.data.content)
		store.commit('setHeader', { name: 'x-user-tenant', content: "Tenant " + result.data.content.selected_organisation })
		next()
	}
	else	
		next('/account/login');
}

const routes = [
	{
		path: '/dashboard',
		name: '',
		component: Dashboard,
		beforeEnter : route_guard,
		children: [
			{ path: '',                    name: 'Dashboard',     component: Home             , meta: { title: "Dashboard Home",   } },
			{ path: 'documents',           name: 'Documents',     component: Documents        , meta: { title: "Documents",        } },
			{ path: 'customers',           name: 'Customers',     component: Customers        , meta: { title: "Customers",        } },
			{ path: 'customer/:_id',       name: 'Customer',      component: Customer         , meta: { title: "One Customer",     } },
			{ path: 'document',            name: 'Document',      component: Documents        , meta: { title: "One Document",     } },
			{ path: 'document/edit/:_id?', name: 'EditDocument',  component: EditDocument     , meta: { title: "Editing Document", parent: { name: 'Documents'} } },
			{ path: 'projects',            name: 'Projects',      component: Projects         , meta: { title: "Projects",         } },
			{ path: 'receipts',            name: 'Receipts',      component: Receipts         , meta: { title: "Receipts",         } },
			{ path: 'receipts/upload',     name: 'UploadReceipt', component: UploadReceipt    , meta: { title: "Upload Receipt",   } },
			{ path: 'financial',           name: 'Financial',     component: FinancialOverview, meta: { title: "Financial",        } },
			{ path: 'vehicles',            name: 'Vehicles',      component: Vehicles         , meta: { title: "Vehicles",        } },
			{ path: 'projects/:_id',       name: 'Project',       component: Project          , meta: { title: "One Project",      } },
			{ path: 'design-guide',        name: 'DesignGuide',   component: DesignGuide      , meta: { title: "Design Guide",     } },
			{ path: 'user-settings',       name: 'UserSettings',  component: UserSettings     , meta: { title: "Your Settings",    } },
		]
	},
	{
		path: '/client-area/document/:document_id/:tenant_id/:user_token/:project_id',
		name: 'ClientArea_ViewDocument',
		component: ClientArea_Document
	},
	{
		path: '/client-area/:project_id/:project_access_key',
		name: 'ClientArea',
		component: ClientArea,
		children: [
			{ path: '', name: 'ClientArea_Home', component: ClientArea_Home },
			{ path: 'documents', name: 'ClientArea_Documents', component: ClientArea_Documents },
			{ path: 'schedule', name: 'ClientArea_Schedule', component: ClientArea_Schedule },
		]
	},
	{
		path: '/account/:form_type',
		name: 'Authentication',
		component: Authentication
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: routes
})


router.beforeEach((to, from, next) =>
{
	if(!has_query_params(to) && has_query_params(from))
	{
		if (to.name != from.name && from !== null)
		{
			console.log("option 1")
			let toWithQuery = Object.assign({}, to, {query: from.query});
			next(toWithQuery);
		}
		else
		{
			console.log("option 2")
			next()
		}
	} 
	else 
		next()
})

export default router